import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

const Animated = ({ children, index }) => {
	return (
		<Transition appear in={!!index} timeout={200}>
			{progress => <AnimatedUI state={progress}>{children}</AnimatedUI>}
		</Transition>
	);
};

const AnimatedUI = styled.div`
	border: none;
	display: ${({ state }) => (state === 'exiting' || state === 'exited' ? 'none' : 'block')};
	opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
	transition: 0.2s;
`;

export default Animated;
