import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';

import { AppProvider } from '../hooks/appContext';
import Layout from '../layout';
import PostListing from '../components/PostListing/PostListing';
import SEO from '../components/SEO/SEO';
import Info from '../components/Info/Info';
import config from '../../data/SiteConfig';
import Header from '../components/Header/Header';
import Fade from '../components/Fade/Fade';
import theme from '../components/theme';

class Work extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
		this.setState({
			isLoaded: true,
		});
		if (typeof window !== `undefined`) {
			let vh = window.innerHeight * 0.01;
			window.document.documentElement.style.setProperty('--vh', `${vh}px`);
			window.addEventListener('resize', () => {
				vh = window.innerHeight * 0.01;
				window.document.documentElement.style.setProperty('--vh', `${vh}px`);
			});
		}
	}

	render() {
		const { isLoaded } = this.state;
		const { data } = this.props;
		const postEdges = data.allMarkdownRemark.edges;
		return (
			<AppProvider>
				<ThemeProvider theme={theme}>
					<Fade isLoaded={isLoaded}>
						<Layout>
							<Helmet title={config.siteTitle} />
							<SEO />
							<Header />
							<ContainerUI id="intersection-observer-root">
								<PostListingUI>
									<PostListing postEdges={postEdges} />
								</PostListingUI>
								<ProjectInfoUI>
									<Info postEdges={postEdges} />
								</ProjectInfoUI>
							</ContainerUI>
						</Layout>
					</Fade>
				</ThemeProvider>
			</AppProvider>
		);
	}
}

const ContainerUI = styled.div`
	display: grid;
	font-family: 'Untitled Sans', sans-serif;
	grid-template-columns: 30% 1fr;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	margin: 0;
	overflow-y: hidden;
	padding: 0;

	@media (max-width: ${props => props.theme.breakpointMobile}) {
		display: flex;
		flex-direction: column;
	}
`;

const PostListingUI = styled.div`
	border-right: 1px solid ${props => props.theme.borderColor};
	overflow: hidden;
	position: relative;
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		border-bottom: 1px solid ${props => props.theme.borderColor};
		border-right: none;
		overflow-y: scroll;
	}
`;

const ProjectInfoUI = styled.div`
	overflow: hidden;
	padding: 24px;
	padding-top: calc(64px + 24px);
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		padding: 0;
	}
`;

export default Work;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
	query IndexQuery {
		allMarkdownRemark(limit: 4000, sort: { fields: [fields___date], order: DESC }) {
			edges {
				node {
					fields {
						slug
						date
					}
					excerpt
					timeToRead
					frontmatter {
						title
						tag
						tags
						index
						oneline
						cover {
							childImageSharp {
								fluid(maxWidth: 1920, maxHeight: 1080) {
									...GatsbyImageSharpFluid
								}
							}
						}
						date
						description
					}
				}
			}
		}
	}
`;

// tv: file(relativePath: { eq: "subtract.png" }) {
// 	childImageSharp {
// 		fluid(quality: 100) {
// 			...GatsbyImageSharpFluid_withWebp
// 		}
// 	}
// }

