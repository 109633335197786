import React from 'react';
import styled from 'styled-components';

const ExternalLink = ({ href, children }) => {
	return (
		<Link href={href} target="_blank" rel="noopener noreferrer">
			{children}
		</Link>
	);
};

const Link = styled.a`
	text-decoration: none;
	color: black;
	cursor: crosshair;
	&:hover {
		background: ${props => props.theme.linkActiveColor};
	}
`;

export default ExternalLink;
