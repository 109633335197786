import React, { useContext } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Typography from '../Typography/Typography';
import { AppContext } from '../../hooks/appContext';
import Animated from '../Animated/Animated';
import About from '../About/About';

const InfoContainer = ({ postEdges }) => {
	const [state] = useContext(AppContext);

	const infos = state.active.map((active, index) => {
		if (state.active.length === 1) {
			index += 1; // hack for initial state
		}
		return <Info postEdges={postEdges} active={active} index={index} key={active} />;
	});

	return <>{infos}</>;
};

const Info = ({ postEdges, active, index }) => {
	let description = '';
	let date = '';
	let tag = '';
	let path = '';
	let img = null;
	let gif = null;
	let about = false;
	let isFluid = false;
	postEdges.forEach(postEdge => {
		if (postEdge.node.frontmatter.title === active) {
			description = postEdge.node.frontmatter.oneline;
			date = postEdge.node.frontmatter.date;
			tag = postEdge.node.frontmatter.tag;
			path = postEdge.node.fields.slug;
			gif = postEdge.node.frontmatter.gif;
			if ( postEdge.node.frontmatter?.cover?.childImageSharp?.fluid ) {
				img = postEdge.node.frontmatter.cover.childImageSharp.fluid;
				isFluid = true;
			} 
		}
	});

	if (path === '/about') {
		about = true;
	}

	return (
		<Animated index={index}>
			<ContainerUI>
				<HeroUI>
					{about ? <About /> : null}
					{ img && isFluid && !about ? 
					<Link to={path}>
						<Img fluid={img} />
					</Link> : null }
				</HeroUI>
				<DescriptionUI>
					<DateUI>
						<div>
							{date.length ? date.split('/')[2] : ''} — {tag}
						</div>
					</DateUI>
					<DescriptionTypography>{description}</DescriptionTypography>
				</DescriptionUI>
			</ContainerUI>
		</Animated>
	);
};

const ContainerUI = styled.div`
	display: grid;
	grid-row-gap: ${props => props.theme.paddingDesktop};
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		grid-column: span 2;
		grid-row-gap: 0;
	}
`;

const DescriptionUI = styled.div`
	grid-row: 2;
	grid-column: 1;
	display: grid;
	grid-template-columns: 30% 1fr;
	grid-column-gap: 12px;
	max-width: 1000px;
	margin-right: 88px;
	@media (max-width: ${props => props.theme.breakpointDesktop}) {
		grid-column: span 2;
	}
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		grid-row: 1;
	}
`;

const DateUI = styled(Typography)`
	font-size: .85rem;
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		grid-column: span 2;
		padding: ${props => props.theme.paddingMobile};
	}
`;

const DescriptionTypography = styled(Typography)`
	font-size: 1.1rem;
	font-family: 'Wremena', serif;
	letter-spacing: 0;
	color: #222;
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		display: none;
	}
`;

const HeroUI = styled.div`
	grid-row: 1;
	grid-column: 1;
	@media (max-width: ${props => props.theme.breakpointDesktop}) {
		grid-column: span 2;
	}
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		grid-row: 2;
		min-height: 300px;
	}
`;

export default InfoContainer;
