import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ExternalLink from '../ExternalLink/ExternalLink';

const Header = () => {
	return (
		<HeaderUI>
			<Title>
				<Link to="/">
					<h1>Jeff Ong</h1>
				</Link>
			</Title>
			<Title>
				<ExternalLink href="https://jffng.blog/">blog</ExternalLink>
				<span> — </span>
				<ExternalLink href="https://docs.google.com/spreadsheets/d/1LtpUlSQJaYhO-Umu28o_GDXFsX0KhAvBiCGuZWp_rgk">
					cv
				</ExternalLink>
				<span> — </span>
				<ExternalLink href="https://github.com/jffng">github</ExternalLink>
				<span> — </span>
				<ExternalLink href="https://linkedin.com/in/ongjeffrey">linkedin</ExternalLink>
			</Title>
		</HeaderUI>
	);
};

const HeaderUI = styled.div`
	background: white;
	border-bottom: 1px solid ${props => props.theme.borderColor};
	padding: ${props => props.theme.paddingDesktop};
	position: fixed;
	width: 100%;
	z-index: 100;
	display: flex;
	justify-content: space-between;

	@media (max-width: ${props => props.theme.breakpointMobile}) {
		padding: ${props => props.theme.paddingMobile};
	}
`;

const Title = styled.div`
	font-size: 0.75rem !important;
	h1 {
		display: inline-block;
		margin: 0;
		font-size: 0.75rem !important;
		font-weight: normal;
	}
	a {
		text-decoration: none;
		color: black;
	}
	font-weight: normal;
	margin: 0 !important;
	@media (max-width: ${props => props.theme.breakpointMobile}) {
		font-size: 1.5em;
	}
`;

export default Header;
